body[data-layout="design-detail"]{



    section{

        &.content {
            .container {
                @media(max-width:1300px){
                    width: 100%;
                    padding: 0 12px;
                }
            }
            &.design__update {

                .widget, aside .widget {
                    padding: 16px;
                }

                .lcol {
                      width: 63%;
                        @media(max-width: 992px){
                            width: 100%;
                        }


                    #shelf-homes-slider .slide a {
                        @media only screen and (max-width: 1300px){
                            height: 400px;
                        }
                        @media only screen and (max-width: 767px){
                            height: 200px;
                        }
                    }

                    .slider-pager {
                        @media(min-width:1300px){
                            .slider-thumbnail {
                                width: 188px;
                                height: 141px;
                                float: left;
                                display: block;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                margin-left: 14px;
                                margin-bottom: 12px;
                                cursor: pointer;

                                &:nth-child(4n + 1) {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                } // lcol
                .rcol {

                    width: 34%;
                    .pricing {
                        p{
                            text-align: center;
                            margin-bottom: 6px;
                        }
                        h4{
                          text-align: center;
                          font-size: 40px;
                          line-height: 1;
                          @media(max-width: 600px){
                            font-size: 24px;
                          }
                        }

                    }

                    ul{
                        &.design_features {
                            padding: 4px 0;
                            width: 300px;
                            display: block;
                            position: relative;
                            margin: 20px auto;
                                @media(max-width: 600px){
                                    width: 190px;
                                    margin: 1px auto;
                                }
                            li {
                                display: inline-block;
                                margin-right: 12px;
                                list-style-type: none;
                                line-height: 50px;
                                padding-left:0;

                                &:before {
                                    content: '';
                                    display: none;
                                }
                                @media(max-width: 767px){
                                    margin-right: 6px;
                                }
                                &:last-of-type{
                                    margin-right: 0;
                                }
                                span {
                                    font-family: "Open Sans", Arial, sans-serif;
                                    color: #4c4c4c;
                                    font-size: 24px;
                                    @media(max-width: 992px){
                                        font-size: 24px;
                                    }
                                    @media(max-width: 600px){
                                        font-size: 14px;
                                    }
                                }
                                &.ico__bedrooms {
                                    background-image: url(/../resources/images/icon_bedrooms.svg);
                                    background-size: 54px 44px;
                                    background-position: 100% 50%;
                                    background-repeat: no-repeat!important;
                                    width: 80px;
                                    margin-right: 24px;

                                    @media(max-width: 992px){
                                        // width: 56px;
                                        // background-size: 30px 23px;
                                        background-size: 54px 44px;
                                        width: 80px;
                                    }
                                    @media(max-width: 600px){
                                        width: 46px;
                                        background-size: 30px 23px;
                                        // background-size: 20px 18px;
                                    }
                                }
                                &.ico__bathrooms {
                                    background-image: url(/../resources/images/icon_bathrooms.svg);
                                    background-size: 54px 49px;
                                    background-position: 100% 50%;
                                    background-repeat: no-repeat!important;
                                    width: 80px;
                                    margin-right: 24px;
                                    @media(max-width: 992px){
                                      //  width: 50px;
                                      //  background-size: 26px 23px;
                                      background-size: 54px 49px;
                                      width: 80px;
                                    }
                                    @media(max-width: 600px){
                                        width: 42px;
                                        background-size: 26px 23px;
                                        // background-size: 20px 18px;
                                    }
                                }
                                &.ico__parking {
                                    background-image: url(/../resources/images/icon_parking.svg);
                                    background-size: 54px 47px;
                                    background-position: 100% 50%;
                                    background-repeat: no-repeat!important;
                                    width: 80px;
                                    @media(max-width: 992px){
                                       // width: 50px;
                                       // background-size: 26px 23px;
                                        background-size: 54px 47px;
                                        width: 80px;
                                    }
                                    @media(max-width: 600px){
                                        width: 42px;
                                        background-size: 26px 23px;
                                        //background-size: 20px 18px;
                                    }

                                }

                            } // li
                        } //design_features
                    } // ul
                    @media(max-width: 992px){
                        width: 100%;
                    }
                    .deatures {
                        margin-bottom: 0;;
                        @media(max-width: 767px){
                            padding-left: 12px;
                        }
                    }
                    .dl { width: 426px;
                        position: relative;
                        margin-bottom: 20px;
                        @media(max-width:1300px){
                            width: 100%;
                        }

                        .list{
                            display: inline-block;
                            position: relative;
                            width: 100%;
                            height: 40px;;
                        }

                    }
                    dt {
                         float: left; width: 326px; overflow: hidden; white-space: nowrap; color: #000; overflow: visible;
                         @media(max-width:1300px){
                            width: 20vw;
                        }
                        span {
                            color: #4c4c4c;
                            font-size: 20px;
                            font-family: 'Open Sans', sans-serif;
                            font-weight: normal;
                            width: auto;
                            @media(max-width:767px){
                                font-size: 16px;
                            }

                        }

                    }
                    dd {
                        float: right;
                        width: auto;
                        overflow: hidden;
                        color: #4c4c4c;
                        /* padding-top: 8px; */
                        text-align: right;
                        position: relative;
                        min-width: 60px;
                        margin-right: 15px;
                        position: absolute;
                        top: 8px;
                        right: 0;

                        @media(max-width:1300px){
                            margin: 0;
                            margin-right: -22px;
                        }

                        @media(max-width:767px){
                            font-size: 16px;
                            margin: 0;
                            margin-right: -22px;
                            padding: 0;
                            margin-top: -4px;
                            top: 0;
                        }

                        .content {
                            padding: 2px 10px 0 4px;
                            i{
                                color: #4c4c4c;;
                                font-size: 22px;
                                font-family: 'Open Sans', sans-serif;
                                font-weight: normal;
                                @media(max-width:767px){
                                    font-size: 16px;
                                }

                            }
                            sup{
                                color: #4c4c4c;;
                                font-size: 22px;
                                font-family: 'Open Sans', sans-serif;
                                font-weight: normal;
                                @media(max-width:767px){
                                    font-size: 16px;
                                }

                            }
                        }


                    }

                    dt span:after { content: " ....................................................................................................................................................................";
                        color: #000;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 22px;
                        font-weight: normal;
                        @media(max-width:767px){
                            font-size: 16px;
                        }

                    }
                    .sidebar__header {
                        background-color: $lightblue!important;
                        padding: 14px 16px;
                        width: 100%;
                        display: block;
                        margin-bottom: 10px;

                        h4 {
                            width: 100%;
                            font-family: 'Open Sans', sans-serif;
                            font-size: 32px;
                            line-height: 1;
                            color: #fff;
                            text-align: left;
                            @media(max-width:1300px){
                                font-size: 20px;
                            }
                        } //h4

                    } // sidebar__header
                    .features__ {
                        background: #fff;
                        position: relative;
                        margin-bottom: 20px;
                        .energy-rating {
                            padding-bottom: 20px;
                            .energy-rating__title {
                                text-align: center;
                                @media only screen and (max-width: 767px){
                                   font-size: 16px;
                                }
                            } //energy-rating__title
                            ul{
                                &.energy-rating__list{
                                    display: inline-block;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 190px;
                                    @media(max-width:1300px){
                                        width: 182px;
                                    }
                                }
                            }
                        } // energy-rating
                    } //features__
                    .content__inclusions {
                        ul {
                            pointer-events: all;
                            li {
                                padding-left: 20px;
                                margin-bottom: 6px;
                                font-size: 16px;
                                line-height: 1.4;
                                @media(max-width:1300px){
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                } //rcol
            } // design__update
        } // content


    }// section

} // body[data-layout="design-detail"
