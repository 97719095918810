body[data-layout="designs"]{

    section {
        &.grid__update {
            ul{
                &.design_features {
                    padding: 4px 0;
                    li {
                        display: inline-block;
                        margin-right: 12px;
                        @media(max-width: 767px){
                            margin-right: 6px;
                        }
                        &:last-of-type{
                            margin-right: 0;
                        }
                        span {
                            font-family: "Open Sans", Arial, sans-serif;
                            color: #4c4c4c;
                            font-size: 20px;
                            @media(max-width: 992px){
                                font-size: 16px;
                            }
                            @media(max-width: 767px){
                                font-size: 14px;
                            }
                        }
                        &.ico__bedrooms {
                            background-image: url(/../resources/images/icon_bedrooms.svg);
                            background-size: 36px 28px;
                            background-position: 100% 50%;
                            background-repeat: no-repeat!important;
                            width: 56px;
                            @media(max-width: 992px){
                                width: 46px;
                                background-size: 30px 23px;
                            }
                            @media(max-width: 767px){
                                width: 36px;
                                background-size: 20px 18px;
                            }
                        }
                        &.ico__bathrooms {
                            background-image: url(/../resources/images/icon_bathrooms.svg);
                            background-size: 32px 28px;
                            background-position: 100% 50%;
                            background-repeat: no-repeat!important;
                            width: 50px;
                            @media(max-width: 992px){
                                width: 40px;
                                background-size: 26px 23px;
                            }
                            @media(max-width: 767px){
                                width: 32px;
                                background-size: 20px 18px;
                            }
                        }
                        &.ico__parking {
                            background-image: url(/../resources/images/icon_parking.svg);
                            background-size: 32px 28px;
                            background-position: 100% 50%;
                            background-repeat: no-repeat!important;
                            width: 50px;
                            @media(max-width: 992px){
                                width: 40px;
                                background-size: 26px 23px;
                            }
                            @media(max-width: 767px){
                                width: 32px;
                                background-size: 20px 18px;
                            }

                        }

                    } // li
                } //design_features
            } // ul

        } // grid__update
    } //section

} // body